import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const Genres = () => {
    const [genres, setGenres] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        // make swal loading
        Swal.fire({
            title: "Loading Genres...",
            didOpen: () => {
                Swal.showLoading();
            },
        });

        setLoading(true);
        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: "GET",
            headers: headers,
        };

        fetch(`${process.env.REACT_APP_BACKEND}/genres`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    setError(data.message);
                } else {
                    setGenres(data);
                }
                setLoading(false);
                Swal.close();
            })
            .catch((err) => {
                console.log(err);
                setError(err.message);
                setLoading(false);
                Swal.close();
            });
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    } else {
        if (error !== null) {
            return <div>Error: {error.message}</div>;
        } else {
            return (
                <div>
                    <h2>Genres</h2>
                    <hr />

                    <div className="list-group">
                        {genres.map((g) => (
                            <Link
                                key={g.id}
                                className="list-group-item list-group-item-action"
                                to={`/genres/${g.id}`}
                                state={
                                    {
                                        genreName: g.genre,
                                    }
                                }
                            >{g.genre}</Link>
                        ))}
                    </div>
                </div>
            );
        }
    }
};

export default Genres;
