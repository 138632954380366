import { useCallback, useEffect, useState } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import Alert from './components/Alert';
function App() {

  const [jwtToken, setJwtToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertClassName, setAlertClassName] = useState("d-none");

  const [tickingInterval, setTickingInterval] = useState(false);

  const navigate = useNavigate();

  const INTERVAL_TIME = 100000; // 10000ms = 10secs. You can change the time.

  const logOut = () => {
    const requestOptions = {
      method: 'GET',
      credentials: 'include',
    };

    fetch(`${process.env.REACT_APP_BACKEND}/logout`, requestOptions)
      .catch((error) => {
        console.log("Error logging out: ", error);
      }).finally(() => {
        setJwtToken("");
        toggleRefresh(false);
      });
    navigate("/login")
  }

  const toggleRefresh = useCallback((status) => {
    // console.log("clicked");
    if (status) {
      console.log("turning on ticking");
      let i = setInterval(() => {
        const requestOptions = {
          method: 'GET',
          credentials: 'include',
        };

        fetch(`${process.env.REACT_APP_BACKEND}/refresh`, requestOptions)
          .then(response => response.json())
          .then(data => {
            if (data.token) {
              setJwtToken(data.token);
            }
          }).catch((error) => {
            console.log("User is not logged in");
          });
      }, INTERVAL_TIME);
      setTickingInterval(i);
      console.log("ticking interval", i);
    } else {
      console.log("turning off ticking");
      clearInterval(tickingInterval);
      setTickingInterval(false);
    }
  }, [tickingInterval])


  useEffect(() => {
    let timer;
    
    const events = [
      "mousedown",
      "mousemove",
      "wheel",
      "keydown",
      "touchstart",
      "scroll",
      "resize",
      "focus",
      "click",
      "keypress",
    ];
  
    // this function sets the timer that logs out the user after 10 secs
    const handleLogoutTimer = () => {
      timer = setTimeout(() => {
        // clears any pending timer.
        resetTimer();
        // Listener clean up. Removes the existing event listener from the window
        Object.values(events).forEach((item) => {
          window.removeEventListener(item, resetTimer);
        });
        logOut();
      }, INTERVAL_TIME); 
    };
  
    // this resets the timer if it exists.
    const resetTimer = () => {
      if (timer) clearTimeout(timer);
    };
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleRefresh, navigate]);

  useEffect(() => {
    if (jwtToken === "") {
      const requestOptions = {
        method: 'GET',
        credentials: 'include',
      };

      fetch(`${process.env.REACT_APP_BACKEND}/refresh`, requestOptions)
        .then(response => response.json())
        .then(data => {
          if (data.token) {
            setJwtToken(data.token);
            toggleRefresh(true);
          }
        }).catch((error) => {
          console.log("User is not logged in");
        });
    }
  }, [jwtToken, toggleRefresh]);

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h1 className="mt-3">Go watch a Movie!</h1>
        </div>
        <div className="col text-end">
          {jwtToken === ""
            ? (<Link to="/login"><span className="badge bg-success">Login</span></Link>)
            : (
              <a href="#!" onClick={logOut}>
                <span className="badge bg-danger">Logout</span>
              </a>
            )
          }
        </div>
        <hr className="mb-3"></hr>
      </div>

      <div className="row">
        <div className="col-md-2">
          <nav>
            <div className="list-group">
              <Link to="/" className="list-group-item list-group-item-action">Home</Link>
              <Link to="/movies" className="list-group-item list-group-item-action">Movies</Link>
              <Link to="/genres" className="list-group-item list-group-item-action">Genres</Link>
              {jwtToken !== "" && (
                <>
                  <Link to="/admin/movie/0" className="list-group-item list-group-item-action">Add Movie</Link>
                  <Link to="/manage-catalogue" className="list-group-item list-group-item-action">Manage Catalogue</Link>
                  <Link to="/graphql" className="list-group-item list-group-item-action">GraphQL</Link>
                </>
              )}
            </div>
          </nav>
        </div>
        <div className="col md-10">
          <Alert
            className={alertClassName}
            message={alertMessage}
          />
          <Outlet context={{
            jwtToken: jwtToken,
            setJwtToken: setJwtToken,
            setAlertMessage: setAlertMessage,
            setAlertClassName: setAlertClassName,
            toggleRefresh: toggleRefresh,
          }} />
        </div>
      </div>
    </div>
  );
}

export default App;