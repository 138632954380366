import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Input from "./form/Input";
import Select from "./form/Select";
import TextArea from "./form/TextArea";
import Checkbox from "./form/Checkbox";
import Swal from 'sweetalert2'


const EditMovie = () => {

    const navigate = useNavigate();
    const { jwtToken } = useOutletContext();

    const [error, setError] = useState(null);
    const [errors, setErrors] = useState([]);

    const mpaaRating = [
        { id: "G", value: "G" },
        { id: "PG", value: "PG" },
        { id: "PG-13", value: "PG-13" },
        { id: "R", value: "R" },
        { id: "NC17", value: "NC17" },
        { id: "18A", value: "18AX" }
    ];

    const hasError = (key) => {
        return errors.indexOf(key) !== -1;
    }

    const [movie, setMovie] = useState({
        id: 0,
        title: "",
        release_date: "",
        runtime: "",
        mpaa_rating: "",
        description: "",
        genres: [],
        genres_array: [Array(13).fill(false)],
    })

    // get id from url
    let { id } = useParams();
    if (id === undefined) {
        id = 0;
    }

    useEffect(() => {
        if (jwtToken === "") {
            navigate("/login");
            return;
        }

        if (id === 0) {
            //adding a movie
            setMovie({
                id: 0,
                title: "",
                release_date: "",
                runtime: "",
                mpaa_rating: "",
                description: "",
                genres: [],
                genres_array: [Array(13).fill(false)],
            })

            const headers = new Headers();
            headers.append("Content-Type", "application/json");

            const options = {
                method: "GET",
                headers: headers,
            };

            fetch(`${process.env.REACT_APP_BACKEND}/genres`, options)
                .then((response) => response.json())
                .then((data) => {
                    const checks = []

                    data.forEach(g => {
                        checks.push({ id: g.id, genre: g.genre, checked: false });
                    })

                    setMovie(movie => ({
                        ...movie,
                        genres: checks,
                        genres_array: [],
                    }));
                })
                .catch((err) => {
                    console.log("Error: ", err);
                });


        } else {
            // editing an existing movie
            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", `Bearer ${jwtToken}`);

            const options = {
                method: "GET",
                headers: headers,
            };

            fetch(`${process.env.REACT_APP_BACKEND}/admin/movies/${id}`, options)
                .then((response) => {
                    if (response.status !== 200) {
                        setError("Error fetching movie", response.status);
                    }
                    return response.json();
                })
                .then((data) => {
                    // data.release_date = new Date(data.release_date).toISOString().split('T')[0];
                    data.movie.release_date = new Date(data.movie.release_date).toISOString().split('T')[0];

                    const checks = [];
                    data.genres.forEach(g => {
                        if (data.movie.genres_array.indexOf(g.id) !== -1) {
                            checks.push({ id: g.id, genre: g.genre, checked: true });
                        } else {
                            checks.push({ id: g.id, genre: g.genre, checked: false });
                        }
                    })

                    // set state
                    setMovie({
                        ...data.movie,
                        genres: checks,
                    })
                })
                .catch(err => {
                    console.log("Error: ", err);
                })
        }


    }, [id, jwtToken, navigate]);

    const handleSubmit = (event) => {
        event.preventDefault();

        let errors = [];
        let required = [
            { field: movie.title, name: "title" },
            { field: movie.release_date, name: "release_date" },
            { field: movie.runtime, name: "runtime" },
            { field: movie.mpaa_rating, name: "mpaa_rating" },
            { field: movie.description, name: "description" },
        ]

        required.forEach((field) => {
            if (field.field === "") {
                errors.push(field.name);
            }
        })

        if (movie.genres_array.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select at least one genre',
                confirmButtonText: 'OK'
            });
            errors.push("genres");
        }

        setErrors(errors);

        if (errors.lenth > 0) {
            return false;
        }

        // passed validation, so save changes
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${jwtToken}`);

        // assume we are adding a new movie
        let method = "PUT"

        if (movie.id > 0) {
            method = "PATCH"
        }

        const requestBody = movie;
        // we need to convert the values in JSON for release date (to date) and runtime (to int)

        requestBody.release_date = new Date(movie.release_date);
        requestBody.runtime = parseInt(movie.runtime, 10);

        let requestOptions = {
            method: method,
            headers: headers,
            body: JSON.stringify(requestBody),
            credentials: "include"
        };

        console.log("requestOptions: ", requestOptions);

        fetch(`${process.env.REACT_APP_BACKEND}/admin/movies/${movie.id}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    console.log("Error: ", data.error);
                    // setError(data.error);
                } else {
                    // success
                    navigate("/manage-catalogue");
                }
            })
            .catch((err) => {
                console.log("Error: ", err);
            });


    }

    const handleChange = () => (event) => {
        let value = event.target.value;
        let name = event.target.name;
        setMovie({
            ...movie,
            [name]: value,
        })
    }

    const handleCheck = (event, index) => {

        console.log("handleCheck ===================== ");
        console.log("value in handleCheck: ", event.target.value);
        console.log("checked is: ", event.target.checked);
        console.log("index is: ", index);

        let tmpArr = movie.genres;
        tmpArr[index].checked = !tmpArr[index].checked;

        let tmpIDs = movie.genres_array;
        if (!event.target.checked) {
            tmpIDs.splice(tmpIDs.indexOf(event.target.value));
        } else {
            tmpIDs.push(parseInt(event.target.value, 10));
        }

        setMovie({
            ...movie,
            genres_array: tmpIDs,
        })
    }

    const confirmDelete = () => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You will not be able to recover this movie!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it!',
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            timer: 20000,
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                let headers = new Headers();
                headers.append("Content-Type", "application/json");
                headers.append("Authorization", `Bearer ${jwtToken}`);

                const options = {
                    method: "DELETE",
                    headers: headers,
                }

                fetch(`${process.env.REACT_APP_BACKEND}/admin/movies/${movie.id}`, options)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            console.log("Error: ", data.error);
                            setError(data.error);
                        } else {
                            // success
                            navigate("/manage-catalogue");

                        }
                    })
                    .catch((err) => {
                        console.log("Error: ", err);
                    });
            }
        })

    }

    if (error) {
        return <div>Error: {error.message}</div>;
    } else {

        return (
            <div>
                <h2>Add/Edit Movie</h2>
                <hr></hr>
                {/* <pre>{JSON.stringify(movie, null, 3)}</pre> */}

                <form onSubmit={handleSubmit}>
                    <input type="hidden" name="id" value={movie.id} id="id" />
                    <Input
                        title={"Title"}
                        className={"form-control"}
                        type={"text"}
                        name={"title"}
                        value={movie.title}
                        onChange={handleChange("title")}
                        errorDiv={hasError("title") ? "text-danger" : "d-none"}
                        errorMsg={"Please enter a title"}
                    />

                    <Input
                        title={"Release Date"}
                        className={"form-control"}
                        type={"date"}
                        name={"release_date"}
                        value={movie.release_date}
                        onChange={handleChange("release_date")}
                        errorDiv={hasError("release_date") ? "text-danger" : "d-none"}
                        errorMsg={"Please enter a release date"}
                    />

                    <Input
                        title={"Runtime"}
                        className={"form-control"}
                        type={"text"}
                        name={"runtime"}
                        value={movie.runtime}
                        onChange={handleChange("runtime")}
                        errorDiv={hasError("runtime") ? "text-danger" : "d-none"}
                        errorMsg={"Please enter a runtime"}
                    />

                    <Select
                        title={"MPAA Rating"}
                        name={"mpaa_rating"}
                        options={mpaaRating}
                        value={movie.mpaa_rating}
                        placeholder={"Choose..."}
                        onChange={handleChange("mpaa_rating")}
                        errorDiv={hasError("mpaa_rating") ? "text-danger" : "d-none"}
                        errorMsg={"Please choose an MPAA rating"}
                    />

                    <TextArea
                        title={"Description"}
                        className={"form-control"}
                        name={"description"}
                        rows={"3"}
                        value={movie.description}
                        onChange={handleChange("description")}
                        errorDiv={hasError("description") ? "text-danger" : "d-none"}
                        errorMsg={"Please enter a description"}
                    />

                    <hr />

                    <h3>Genres</h3>

                    {movie.genres && movie.genres.length > 1 &&
                        <>
                            {Array.from(movie.genres).map((g, i) => (

                                <Checkbox
                                    title={g.genre}
                                    name={"genre"}
                                    key={i}
                                    id={"genre-" + g.id}
                                    value={g.id}
                                    onChange={(event) => handleCheck(event, i)}
                                    checked={movie.genres[i].checked}
                                />
                            ))}
                        </>
                    }

                    <hr />

                    <button className="btn btn-primary">Save</button>

                    {movie.id > 0 &&
                        <a href="#!" className="btn btn-danger ms-2" onClick={confirmDelete}>Delete Movie</a>
                    }
                </form>
            </div>
        )
    }
}

export default EditMovie;