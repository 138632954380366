import { useEffect, useState } from 'react';
import Input from './form/Input';
import { Link } from 'react-router-dom';

const GraphQL = () => {
    // set up stateful variables
    const [movies, setMovies] = useState([])
    const [searchTerm, setSearchTerm] = useState('')

    const [fullList, setFullList] = useState([])

    // perform a search
    const performSearch = () => {
        const payload = `{
            search(titleContains: "${searchTerm}") {
                id
                title
                runtime
                release_date
                mpaa_rating
            }
        }`;

        const headers = new Headers();
        headers.append('Content-Type', 'application/graphql');

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: payload
        };

        fetch(`${process.env.REACT_APP_BACKEND}/graphql`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                let theList = Object.values(response.data.search);

                console.log(theList);

                setMovies(theList);
            })
            .catch(error => { console.log('error', error) });
    }

    const handleChange = (event) => {
        event.preventDefault();

        let value = event.target.value;
        setSearchTerm(value);

        if(value.length > 2) {
            performSearch();
        } else {
            setMovies(fullList);
        }
    }

    // useEffect hook
    useEffect(() => {
        const payload = `
            {
                list {
                    id
                    title
                    runtime
                    release_date
                    mpaa_rating
                }
            }`;

        const headers = new Headers();
        headers.append('Content-Type', 'application/graphql');

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: payload
        };

        fetch(`${process.env.REACT_APP_BACKEND}/graphql`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                let theList = Object.values(response.data.list);

                console.log(theList);

                setMovies(theList);

                // console.log(movies);

                setFullList(theList);
            })
            .catch(error => { console.log('error', error) });

    }, [])

    return (
        <div>
            <h2>GraphQL</h2>
            <hr></hr>

            <form onSubmit={handleChange}>
                <Input
                    title={"Search"}
                    type={"text"}
                    name={"searchTerm"}
                    value={searchTerm}
                    className={"form-control"}
                    onChange={handleChange}
                />

            </form>

            {movies ? (
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Release Date</th>
                            <th>Runtime</th>
                            <th>MPAA Rating</th>
                        </tr>
                    </thead>
                    <tbody>
                        {movies.map((m) => {
                            return <tr key={m.id}>
                                <td>
                                    <Link to={`/movies/${m.id}`}>
                                        {m.title}
                                    </Link>
                                </td>
                                <td>{new Date(m.release_date).toLocaleDateString()}</td>
                                <td>{m.runtime}</td>
                                <td>{m.mpaa_rating}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            ) : (
                <p>No movies (yet)!.</p>
            )}
        </div>
    )
}

export default GraphQL;